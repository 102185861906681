<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Melhor insulfilm para privacidade de janelas</h2>

            <p>
              O insulfilm com o melhor fator de privacidade é o
              <strong>Insulfilm blackout</strong>, que possui 100% de barreira
              visual e 100% de bloqueio de luz. Outras opções incluem
              <strong>whiteout</strong>, uma película como a blackout só que na
              cor branca, e <strong>películas jateadas</strong> com privacidade
              moderada.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>
              Insulfilmes que podem ser usados para privacidade de janelas
            </h3>

            <p>
              Hoje, existem vários tipos de insulfilmes para janelas de
              isolamento residencial para escolher, como fumê, fosco, jateado e
              refletivos. Essas películas isolantes são cada vez mais procuradas
              por clientes que buscam praticidade e estética das janelas. O Insulfilm tem
              muitas vantagens devido às suas múltiplas funções, e pode ter um
              impacto positivo no ambiente em que está instalado
            </p>
            <p>
              O aumento da privacidade é um dos recursos mais procurados pelos
              clientes. Certos tipos de insulfilmes reduzem ou bloqueiam
              completamente a visibilidade, dependendo do nível de opacidade ou
              espelhamento.
            </p>

            <p>
              O <strong>filme jateado</strong>, também conhecido como filme fosco, reduz
              parcialmente a visibilidade, proporciona transparência à luz e
              maior privacidade.
            </p>
            <p>
              A <strong>película espelhada</strong> também oferece uma certa privacidade
              dependendo de onde à luz irá incidir mais, além de proporcionar um
              conforto térmico.
            </p>

            <p>
              Se você deseja privacidade
              <strong> sem comprometer a visão do lado de fora</strong>, os
              insulfilmes <strong>fumê ou espelhados</strong> são boas opções,
              principalmente as tonalidades G5 e G20.
            </p>

            <p>
              A <strong>película para janela jateada</strong> é uma ótima opção
              se você deseja privar o local onde será aplicado sem
              <strong>tirar a luminosidade</strong>.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[1].thumb"
                :title="photos[1].title"
                :description="photos[1].description"
                :idLocation="photos[1].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Aplicação do melhor insulfilm para privacidade de janelas</h3>

            <p>
              É possível aplicar insulfilmes para janelas com materiais de
              qualidade e com garantia de fábrica, impedindo a visualização de
              fora para o interior do ambiente, gerando privacidade e reduzindo
              o calor e radiação UV do ambiente.
            </p>

            <p>
              A <router-link :to="{ name: 'about' }">MF Film</router-link> tem
              mais de 18 anos de experiência com profissionais especializados na
              instalação de insulfilms e garante o serviço completo.
            </p>

            <p>
              <router-link :to="{ name: 'quotation' }"
                >Faça uma cotação</router-link
              >, fale com um especialista e veja como as películas podem ajudar na privacidade das janelas.
            </p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
  },

  data() {
    return {
      layoutLoad: false,
      index: null,
      photos: [
        {
          description:
            "Filme G5 aplicado no SENAC para escurecer e proteger o ambiente dos raios solares",
          idLocation: 1,
          src: "/images/fotos/senac/pelicula-filme-g5-senac-44",
          thumb:
            "/images/fotos/senac/small/pelicula-filme-g5-senac-44",
          title: "Película para Janela",
        },
        {
          description:
            "Insulfilm branco jateado aplicado na janela para fornecer pricavidade ao local",
          idLocation: 1,
          src: "/images/fotos/condominio-branco-jateado/pelicula-insulfilm-branco-jateado-condominio-5",
          thumb:
            "/images/fotos/condominio-branco-jateado/small/pelicula-insulfilm-branco-jateado-condominio-5",
          title: "Película para Janela",
        },

      ],
    };
  },

  mounted: function () {
    //console.log(this.photos)
    // console.log('mounted')
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(
      `update:title`,
      "Qual o melhor insulfilm para privacidade de janelas?"
    );
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm blackout, whiteout e películas jateadas para privacidade"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>